<div class="card">
  <h4 class="card-header">Yeni Talep Oluştur</h4>
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="text">Konu</label>
        <input
          type="text"
          formControlName="konu"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.konu.errors }"
        />
        <div *ngIf="submitted && f.konu.errors" class="invalid-feedback">
          <div *ngIf="f.konu.errors.required">Konu Boş olamaz</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Açıklama</label>

        <textarea
          formControlName="sikayet"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.sikayet.errors }"
          rows="3"
        ></textarea>
        <div *ngIf="submitted && f.sikayet.errors" class="invalid-feedback">
          <div *ngIf="f.sikayet.errors.required">Şikayet Boş Olamaz</div>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Oluştur
        </button>
      </div>
    </form>
  </div>
</div>
