import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginModel } from '../models/loginModel';
import { SingleResponseModel } from '../models/responses/singleResponseModel';
import { UserModel } from '../models/userModel';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<UserModel>;
  public user: Observable<UserModel>;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.userSubject = new BehaviorSubject<UserModel>(
      JSON.parse(localStorage.getItem('User'))
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): UserModel {
    return this.userSubject.value;
  }

  login(loginModel: LoginModel) {
    loginModel.CihazNo = 'WEB';
    return this.httpClient
      .post<SingleResponseModel<UserModel>>(
        environment.apiUrl + 'TalepToken',
        loginModel
      )
      .pipe(
        map((res) => {
          this.localStorageService.setUser(res.Veri);
          this.userSubject.next(res.Veri);
          return res;
        })
      );
  }

  isAuthenticated(): boolean {
    return !!this.localStorageService.getUser();
  }

  logout() {
   
    this.userSubject.next(null);
    this.localStorageService.removeUser();

    this.router.navigate(['/auth/login']);
  }
}
