import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardModel } from 'src/app/models/dashboardModel';
import { TalepService } from 'src/app/services/talep.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private talepService: TalepService,
    private toastrService: ToastrService
  ) {}
  dashboard: DashboardModel;
  dataLoaded = false;
  ngOnInit(): void {
    this.GetDashBoard();
  }

  GetDashBoard() {
    this.talepService.GetDashBoard().subscribe((a) => {
      console.log(a);
      if (a.BasariliMi) {
        this.dashboard = a.Veri;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
      this.dataLoaded = true;
    });
  }
}
