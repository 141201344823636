import { Injectable } from '@angular/core';
import { UserModel } from '../models/userModel';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  userKey: string = 'User';

  constructor() { }

  setUser(tokenValue: UserModel) {
    localStorage.setItem(this.userKey, JSON.stringify(tokenValue));
  }

  getUser(): UserModel {
    return JSON.parse(localStorage.getItem(this.userKey));
  }
  

  removeUser() {
    localStorage.removeItem(this.userKey);
  }

}
