<footer class="sticky-footer bg-white">
  <div class="container my-auto">
    <div class="copyright text-center my-auto">
     
      <a href="https://www.servispozitif.com/" target="_top"
        >Servis Pozitif 2020 © Beyes Bilişim Teknolojileri Elektronik Yazilim Danişmanlik
        Sanayi ve Ticaret ltd. Şti. - Tüm hakları saklıdır.</a
      >
    </div>

    <div class="text-center mt-4">
      <div class="text-center">info@beyes.com.tr - www.beyes.com.tr</div>
      <div class="text-center">Whatsapp Destek: +90 542 483 08 34</div>
      <div class="text-center">
        Çağrı Merkezi: 90 542 483 08 34 - destek@servispozitif.com
      </div>
    </div>
  </div>
</footer>

<!-- credits -->
