import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TalepService } from 'src/app/services/talep.service';
import { ToastrService } from 'ngx-toastr';
import {
  KayitTurDtoModel,
  KayitTurModel,
  UserModel,
} from 'src/app/models/talepAdd2Model';
import { Observable } from 'rxjs';
import { TalepAdd2Model } from 'src/app/models/talepAddInputModel';
@Component({
  selector: 'app-talepadd2',
  templateUrl: './talepadd2.component.html',
  styleUrls: ['./talepadd2.component.css'],
})
export class Talepadd2Component implements OnInit {
  form: FormGroup;
  loading = false;
  dataLoaded = false;
  submitted = false;
  kayitTurModels: KayitTurModel[];
  Users: UserModel[];
  myFiles: string[] = [];
  model: TalepAdd2Model;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private talepService: TalepService
  ) {}

  ngOnInit() {
    this.GetIsTurList();
    this.form = this.formBuilder.group({
      oncelik: ['', Validators.required],
      cihazturid: ['', Validators.required],
      personelid: ['', Validators.required],
      konu: ['', Validators.required],
      sikayet: ['', Validators.required],
      file: ['', null],
    });
  }
  get f() {
    return this.form.controls;
  }
  onFileChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }

  changeCity(e: any) {
    console.log(e.target.value);
    this.Users = this.kayitTurModels.filter(
      (p) => p.Id == e.target.value
    )[0].Users;
  }

  GetIsTurList() {
    this.talepService.GetIsTurList().subscribe((a) => {
      console.log(a);
      if (a.BasariliMi) {
        this.kayitTurModels = a.Veri;
      } else {
        this.toastrService.info(a.Mesaj, 'Dikkat');
      }
      this.dataLoaded = true;
    });
  }
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';

  fileInfos?: Observable<any>;

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  onSubmit() {
    this.submitted = true;

    this.model = Object.assign({}, this.form.value);

    // stop here if form is invalid
    if (this.form.invalid) {
      
        this.toastrService.warning("Lütfen zorunlu alanları Doldurun.", 'Dikkat');
      return;
    }
    this.loading = true;

   // let add = Object.assign({}, this.form.value);
    this.talepService.TalepAdd2( this.model, this.myFiles).subscribe(
      (res) => {
        if (res.BasariliMi) {
          this.toastrService.info('Giriş Başarılı', 'Başarılı!');
          this.router.navigateByUrl('/talep');
        } else {
          this.toastrService.error(res.Mesaj, 'Dikkat!1');
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
        this.toastrService.error(err.Mesaj, 'Dikkat!2');
        this.loading = false;
      }
    );
  }
}
