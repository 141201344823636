<div id="content-wrapper" class="d-flex flex-column">
  <!-- Main Content -->
  <div id="content">
    <!-- Begin Page Content -->
    <div class="container-fluid">
      <!-- Page Heading -->
      <h1 class="h3 mb-2 text-gray-800">Ön Talep Listesi</h1>

      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Liste</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <mat-table [dataSource]="listData" matSort>
              <ng-container matColumnDef="Id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Talep No
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.Id }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ad">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Talep Aciklama
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.ad }} {{ element.soyad }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="konu">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  konu
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.konu }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="InsertDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Tarih
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.InsertDate | date: "dd/MM/yyyy hh:mm" }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="servisId">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row; let element">
                  <span class="badge badge-danger" *ngIf="!element.IsActive"
                    >İptal Edildi</span
                  >

                  <span
                    class="badge badge-warning"
                    *ngIf="element.IsActive && element.servisId == null"
                    >Onay Bekliyor</span
                  >

                  <button
                    *ngIf="element.servisId"
                    value="res"
                    mat-icon-button
                    (click)="routerDetay(element)"
                  >
                    <mat-icon>exit_to_app</mat-icon>
                    Görüntüle
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>

              <mat-footer-row
                [ngClass]="{ hide: listData != null }"
              ></mat-footer-row>
              <mat-footer-row
                [ngClass]="{
                  hide: !(listData != null && listData.data.length == 0)
                }"
              >
              </mat-footer-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[5, 10, 20]"
              [pageSize]="10"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <!-- Page Heading -->
      <h1 class="h3 mb-2 text-gray-800">Talep Listesi</h1>

      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Liste</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <mat-table [dataSource]="listData2" matSort>
              <ng-container matColumnDef="Id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Talep No
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.ServisId }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ad">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Talep Aciklama
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.AdSoyad }} 
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="konu">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  konu
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.talepbaslik }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="InsertDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  Tarih
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.OlusturmaTarihi  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="servisId">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row; let element">
                 

                 

                  <button
                    value="res"
                    mat-icon-button
                    (click)="routerDetay(element)"
                  >
                    <mat-icon>exit_to_app</mat-icon>
                    {{ element.ServisDurumu}}
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>

              <mat-footer-row
                [ngClass]="{ hide: listData2 != null }"
              ></mat-footer-row>
              <mat-footer-row
                [ngClass]="{
                  hide: !(listData2 != null && listData2.data.length == 0)
                }"
              >
              </mat-footer-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[5, 10, 20]"
              [pageSize]="10"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
