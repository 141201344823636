import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { TalepService } from 'src/app/services/talep.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-talepadd',
  templateUrl: './talepadd.component.html',
  styleUrls: ['./talepadd.component.css'],
})
export class TalepaddComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder, 
    private toastrService: ToastrService,
    private router: Router,
    private talepService: TalepService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      konu: ['', Validators.required],
      sikayet: ['', Validators.required], 
    });
  }
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    let add = Object.assign({}, this.form.value);
    this.talepService.TalepAdd(add).subscribe(
      (res) => {
        if (res.BasariliMi) {
          this.toastrService.info('Giriş Başarılı', 'Başarılı!');
          this.router.navigateByUrl('/talep');
        } else {
          this.toastrService.error(res.Mesaj, 'Dikkat!1');
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
        this.toastrService.error(err.Mesaj, 'Dikkat!2');
        this.loading = false;
      }
    );
  }
}
