import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListResponseModel } from '../models/responses/listResponseModel';
import { TalepModel } from '../models/talepModel';

import { SingleResponseModel } from '../models/responses/singleResponseModel';
import { ResponseModel } from '../models/responses/responseModel';
import { TalepDetayModel } from '../models/talepdetayModel';
import { DashboardModel } from '../models/dashboardModel';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { KayitTurModel } from '../models/talepAdd2Model';
import { TalepAddModel } from '../models/talepAddModel';
import { TalepAdd2Model } from '../models/talepAddInputModel'; 
import { ServisListModelOut } from '../models/ServisListModelOut';
import { YorumAddModel } from '../models/yorumAddModel';

@Injectable({
  providedIn: 'root',
})
export class TalepService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {}

  GetTalepList() {
    return this.httpClient
      .get<ListResponseModel<TalepModel>>(
        environment.apiUrl + 'Hizmet/GetIstekMusteriList'
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }

  GetServisList() {
    return this.httpClient
      .get<ListResponseModel<ServisListModelOut>>(
        environment.apiUrl + 'Hizmet/GetServisList'
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }


  GetIsTurList() {
    return this.httpClient
      .get<ListResponseModel<KayitTurModel>>(
        environment.apiUrl + 'Hizmet/GetSirketCihazTurList'
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }

  GetDashBoard() {
    return this.httpClient
      .get<SingleResponseModel<DashboardModel>>(
        environment.apiUrl + 'Hizmet/GetDashBoard'
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }

  getTokenKontrol(responseModel: any) {
    if (!responseModel.BasariliMi && responseModel.MesajKod == 'E0006') {
      this.authService.logout();
    }
    return responseModel;
  }

  TalepAdd(talepadd: TalepAddModel) {
    return this.httpClient
      .post<ResponseModel>(
        environment.apiUrl + 'Hizmet/SetServisTalepKaydet2',
        talepadd
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }

  YorumAdd(yorum: YorumAddModel) {
    return this.httpClient
      .post<ResponseModel>(
        environment.apiUrl + 'Hizmet/YorumEkle',
        yorum
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }


  TalepAdd2(talepadd: TalepAdd2Model, myFiles: any) {
    const formData = new FormData();

    for (var i = 0; i < myFiles.length; i++) {
      formData.append('file'+i, myFiles[i]);
    }
    
    var user = this.authService.userValue;

    formData.append('musteriid', user.MusteriId);
    formData.append('cihazturid', talepadd.cihazturid.toString());
    formData.append('personelid', talepadd.personelid.toString());
    formData.append('konu', talepadd.konu);
    formData.append('sikayet', talepadd.sikayet);
    formData.append('oncelik', talepadd.oncelik.toString()); 


    return this.httpClient
      .post<any>(
        environment.apiUrl + 'Hizmet/YeniServisResimEkle',
        formData
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }

  getTalepDetay(id: number) {
    return this.httpClient
      .get<SingleResponseModel<TalepDetayModel>>(
        environment.apiUrl + 'Hizmet/GetServisDetay?servisid=' + id
      )
      .pipe(
        map((res) => {
          return this.getTokenKontrol(res);
        })
      );
  }
}
