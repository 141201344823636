<div *ngIf="dataLoaded == false" class="d-flex justify-content-center">
  <div class="spinner-border text-success" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div class="main-card mb-3 card" *ngIf="dataLoaded == true && talep">
  <div class="card-header">
    <div class="btn-actions-pane-right">
      <div class="nav">
        <a
          data-toggle="tab"
          href="#tab-eg4-0"
          class="
            border-0
            btn-pill btn-wide btn-transition btn btn-outline-danger
            active
          "
          >Talep Bilgileri</a
        >
        <a
          data-toggle="tab"
          href="#tab-eg4-1"
          *ngIf="talep.CihazDetayModel.CihazTuru"
          class="
            mr-1
            ml-1
            btn-pill btn-wide
            border-0
            btn-transition btn btn-outline-danger
          "
          >Cihaz Bilgisi</a
        >

        <a
          *ngIf="null"
          data-toggle="tab"
          href="#tab-eg4-2"
          class="
            border-0
            btn-pill btn-wide btn-transition btn btn-outline-danger
          "
          >Müşteri Bilgisi</a
        >
        <a
          data-toggle="tab"
          href="#tab-eg4-3"
          class="
            border-0
            btn-pill btn-wide btn-transition btn btn-outline-danger
          "
          >İşlem Statüleri</a
        >
        <a
          data-toggle="tab"
          href="#tab-eg4-4"
          class="
            border-0
            btn-pill btn-wide btn-transition btn btn-outline-danger
          "
          >Dosyalar</a
        >
        <a
          data-toggle="tab"
          href="#tab-eg4-5"
          class="
            border-0
            btn-pill btn-wide btn-transition btn btn-outline-danger
          "
          >Mesajlar</a
        >
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content">
      <div class="tab-pane active" id="tab-eg4-0" role="tabpanel">
        <div class="alert alert-danger" role="alert">
          Talep No: {{ talep.ServisDetayModel.ServisId }}
        </div>
        <div class="alert alert-primary" role="alert">
          Statü: {{ talep.ServisDetayModel.ServisDurumu }}
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="badge badge-secondary">Öncelik Durumu</label>
            <p class="">{{ talep.ServisDetayModel.oncelik }} .Seviye</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Kayit Zamani</label>
            <p class="">{{ talep.ServisDetayModel.KayitZamani }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Teslim Edilen Ürünler</label>
            <p class="">{{ talep.ServisDetayModel.TeslimEden }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Kaydı Alan</label>
            <p class="">{{ talep.ServisDetayModel.TeslimAlan }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">İlgili Personel</label>
            <p class="">{{ talep.ServisDetayModel.Teknisyen }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Personel Telefon</label>
            <p class="">{{ talep.ServisDetayModel.TeknisyenTel }}</p>
          </div>

          <div class="col-md-4">
            <label class="badge badge-secondary">Talep</label>
            <p class="">{{ talep.ServisDetayModel.talepbaslik }}</p>
          </div>

          <div class="col-md-4">
            <label class="badge badge-secondary">Talep Açıklama</label>
            <p class="">{{ talep.ServisDetayModel.ArizaNedeni }}</p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <label class="badge badge-secondary">Yapılan İşlem </label>
            <p class="">{{ talep.ServisDetayModel.YapilanIslemler }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Detay Açıklama</label>
            <p class="">{{ talep.ServisDetayModel.Aciklama }}</p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <label class="badge badge-secondary">Oluşturma Zamanı</label>
            <p class="">{{ talep.ServisDetayModel.KayitZamani }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">İşleme Başlama Zamanı</label>
            <p class="">{{ talep.ServisDetayModel.IslemBasZamani }}</p>
          </div>
        </div>
      </div>
      <div
        class="tab-pane"
        id="tab-eg4-1"
        *ngIf="talep.CihazDetayModel.CihazTuru"
        role="tabpanel"
      >
        <div class="row">
          <div class="col-md-4">
            <label class="badge badge-secondary">Firma</label>
            <p class="">{{ talep.CihazDetayModel.CihazTuru }}</p>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="tab-eg4-2" role="tabpanel">
        <div class="row">
          <div class="col-md-4">
            <label class="badge badge-secondary">Firma</label>
            <p class="">{{ talep.MusteriDetay.firmaadi || "-" }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Ad Soyad</label>
            <p class="">{{ talep.MusteriDetay.AdSoyad }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Cep Telefon</label>
            <p class="">{{ talep.MusteriDetay.CepTel }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Email</label>
            <p class="">{{ talep.MusteriDetay.Email }}</p>
          </div>
          <div class="col-md-4">
            <label class="badge badge-secondary">Adres</label>
            <p class="">{{ talep.MusteriDetay.Adres }}</p>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="tab-eg4-3" role="tabpanel">
        <div class="row">
          <div class="col-md-12">
            <div class="card shadow">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  İşlem Statüleri
                </h6>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sıra</th>
                      <th scope="col">Statü Adı</th>
                      <th scope="col">İlgili Personel</th>
                      <th scope="col">Yapılan İşlem</th>
                      <th scope="col">İşlem Süresi</th>
                      <th scope="col">İşlem Tarihi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of talep.GecisLog; index as i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.statuad }}</td>
                      <td>{{ item.PersonelAd }}</td>

                      <td>{{ item.yapilanis || "-" }}</td>
                      <td>{{ item.sonlanmasuresi || "Devam Ediyor" }}</td>
                      <td>{{ item.crtdate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="tab-eg4-4" role="tabpanel">
        <div class="row">
          <div class="col-md-12" *ngIf="talep.ServisDetayResim">
            <div class="overflow-auto">
              <div class="card shadow">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Dosyalar</h6>
                </div>
                <div class="card-group">
                  <div
                    class="col-md-3"
                    *ngFor="let item of talep.ServisDetayResim"
                  >
                    <img
                      class="card-img-top"
                      src="{{ item.resimurl }}"
                      alt="Card image cap"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane" id="tab-eg4-5" role="tabpanel">
        <div class="row">
          <div class="col-md-12">
            <div class="mesgs">
              <div class="msg_history">
                <div
                  *ngFor="let item of talep.Notlar; index as i"
                  class="{{
                    item.personelid ? 'incoming_msg' : 'outgoing_msg'
                  }}"
                >
                  <div class="incoming_msg_img" *ngIf="item.personelid">
                    <img src="/assets/img/user-profile.png" />
                  </div>
                  <div
                    class="{{ item.personelid ? 'received_msg' : 'sent_msg' }}"
                  >
                    <div class="received_withd_msg">
                      <p>{{ item.aciklama }}</p>
                      <span class="time_date"> {{ item.crttar }}</span>
                    </div>
                  </div>
                </div>
              
                <div class="type_msg" style="display: none">
                  <div class="input_msg_write">
                    <input
                      type="text"
                      class="write_msg"
                      placeholder="Yorum Yazın"
                    />
                    <div class="form-group">
                      <button [disabled]="loading" class="btn btn-primary">
                        <span
                          *ngIf="loading"
                          class="spinner-border spinner-border-sm mr-1"
                        ></span>
                        Oluştur
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <button
          type="button"
          class="btn btn-info"
          data-toggle="modal"
          data-target="#myModal"
        >
          Mesaj Yaz
        </button>
  
        <!-- Modal -->
        <div id="myModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
               
              </div>
              <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="text">Mesaj Yazın</label>
            
                    <textarea
                      formControlName="aciklama"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.aciklama.errors }"
                      rows="3"
                    ></textarea>
                    <div *ngIf="submitted && f.aciklama.errors" class="invalid-feedback">
                      <div *ngIf="f.aciklama.errors.required">Açıklama Boş Olamaz</div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div>
                      <button  [disabled]="loading"
                        type="submit"
                        class="btn btn-primary btn-lg btn-block"
                      >
                      <span
                      *ngIf="loading"
                      class="spinner-border spinner-border-sm mr-1"
                    ></span>
                        Gönder
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
     
      <div class="d-block text-right card-footer"></div>
    </div>
  </div>
</div>
