<div *ngIf="dataLoaded == false" class="d-flex justify-content-center">
  <div class="spinner-border text-success" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div class="container-fluid" *ngIf="dataLoaded && dashboard">
  <!-- Content Row -->
  <div class="row">
   
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
               Günlük Talep Sayısı
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{dashboard.Serviskayitsay.Bugun}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
               Haftalık Talep Sayısı
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{dashboard.Serviskayitsay.Hafta}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
              Aylık Talep Sayısı
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{dashboard.Serviskayitsay.Ay}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div
                class="text-xs font-weight-bold text-primary text-uppercase mb-1"
              >
               Yıllık Talep Sayısı
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{dashboard.Serviskayitsay.Yil}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-comments fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->

  <div class="row">
    <!-- Area Chart -->
    <div class="col-md-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
          class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
          <h6 class="m-0 font-weight-bold text-primary">Talep Listesi</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let item of dashboard.ServistipList"
            >
              {{item.Text}}
              <span class="badge badge-primary badge-pill">  {{item.Value}}</span>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
